(function() {
    'use strict';

    angular.module('ionicFilter', [
        'ion-autocomplete',
        'ionic',
        'ionicAlert',
        'ionicRequest',
        'uabDefaultVariable',
        'uabPromiseManagement'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicFilter').service('FilterService', FilterService);

    FilterService.$inject = [
        'DefaultVariableService',
        'PromiseManagementService'
    ];

    function FilterService(
        DefaultVariableService,
        PromiseManagementService
    ) {
        var FilterService = this;

        FilterService.compare = compare;
        function compare(objectA, objectB) {
            if (
                DefaultVariableService.isDefined(objectA.id) &&
                DefaultVariableService.isDefined(objectB.id)
            ) {
                if (objectA.id === objectB.id) {
                    return true;
                }
            } else {
                if (objectA === objectB) {
                    return true;
                }
            }
        }

        FilterService.filter = filter;
        function filter(objects, query, properties, required) {
            objects = DefaultVariableService.getArray(objects);

            query = DefaultVariableService.getString(query);

            properties = DefaultVariableService.get(
                properties,
                FilterService.properties
            );

            required = DefaultVariableService.get(
                required,
                FilterService.required
            );

            var requiredKeys = Object.keys(required);

            var requiredKeysLength = requiredKeys.length;

            var availableObjects = (requiredKeysLength === 0) ?
                objects : objects.filter(
                    function(object) {
                        for (var i = 0; i < requiredKeysLength; i++) {
                            var expectedValue = '';
                            if (DefaultVariableService.isDefined(required[requiredKeys[i]])) {
                                expectedValue = required[requiredKeys[i]];
                                if (!DefaultVariableService.isString(expectedValue)) {
                                    expectedValue = String(expectedValue);
                                }
                            } else {
                                break;
                            }

                            var actualValue = DefaultVariableService.getString(
                                object[requiredKeys[i]]
                            );

                            if (!FilterService.caseSensitive) {
                                actualValue = actualValue.toLowerCase();
                                expectedValue = expectedValue.toLowerCase();
                            }

                            if (!actualValue.includes(expectedValue)) {
                                return false;
                            }
                        }

                        return true;
                    }
                );

            var propertiesLength = properties.length;

            if (propertiesLength !== 0) {
                availableObjects = availableObjects.filter(
                    function (object) {
                        for (var i = 0; i < propertiesLength; i++) {
                            var value = DefaultVariableService.getString(
                                object[properties[i]]
                            );

                            if (!FilterService.caseSensitive) {
                                value = value.toLowerCase();
                                query = query.toLowerCase();
                            }

                            if (value.match(query)) {
                                return true;
                            }
                        }

                        return false;
                    }
                );
            }

            return PromiseManagementService.generateSuccess(availableObjects);
        }

        FilterService.filterPromise = filterPromise;
        function filterPromise(objects, query, properties, required) {
            return PromiseManagementService.generateSuccess(
                FilterService.filter(objects, query, properties, required)
            );
        }

        FilterService.reset = reset;
        function reset() {
            FilterService.caseSensitive = false;

            FilterService.properties = [];

            FilterService.required = {};
        }

        FilterService.reset();

        return FilterService;
    }
})();
(function() {
    'use strict';

    angular.module('ionicFilter').controller(
        'FilterController',
        FilterController
    );

    FilterController.$inject = [
        'DefaultVariableService',
        'FilterService',
        'RequestManagementService',
        '$scope'
    ];
    
    function FilterController(
        DefaultVariableService,
        FilterService,
        RequestManagementService,
        $scope
    ) {
        var FilterController = this;

        FilterController.allObjects = [];

        FilterController.externalModel = DefaultVariableService.getObject();

        FilterController.isLoading = true;

        FilterController.objects = [];

        FilterController.target = '';

        $scope.$watch(
            'allowRemove',
            function(allowRemove) {
                FilterController.allowRemove = DefaultVariableService.get(
                    allowRemove,
                    true
                );
            }.bind(FilterController)
        );

        $scope.$watch(
            'onFilter',
            function(onFilter) {
                FilterController.onFilter = DefaultVariableService.get(
                    onFilter,
                    false
                );
            }.bind(FilterController)
        );

        $scope.$watch(
            'onSelect',
            function(onSelect) {
                FilterController.onSelect = DefaultVariableService.getPromise(
                    onSelect
                );
            }.bind(FilterController)
        );

        $scope.$watch(
            'target',
            function(target) {
                target = angular.copy(target);

                FilterController.isArray = DefaultVariableService.isArray(
                    target
                );

                var arrayChanged = false;
                var objectChanged = false;

                if (FilterController.isArray) {
                    if (DefaultVariableService.isString(FilterController.target)) {
                        FilterController.target = [];

                        arrayChanged = true;
                    } else {
                        arrayChanged = FilterController.target.length !== target.length;
                    }
                } else {
                    target = DefaultVariableService.getObject(
                        target
                    );

                    if (target.id !== 0) {
                        objectChanged = FilterController.target.id !== target.id;
                    }
                }

                FilterController.maxSelected = FilterController.isArray ? 99 : 1;

                if (arrayChanged || objectChanged) {
                    FilterController.setTarget(target);
                }
            }.bind(FilterController)
        );

        FilterController.filter = filter;
        function filter(query) {
            FilterController.isLoading = true;

            var options = RequestManagementService.getRequest();



            if (DefaultVariableService.isDefined(query)) {
                options = RequestManagementService.setParam(
                    options,
                    [
                        FilterController.title + '%=' + query
                    ]
                );
            }

            options = RequestManagementService.setPage(options, 1);

            return FilterController.onFilter(options).then(
                function(response) {
                    if (response) {
                        if (DefaultVariableService.isArray(response)) {
                            FilterController.setObjects(
                                response
                            );
                        } else {
                            FilterController.setObjects(
                                response[response.model]
                            );
                        }
                    }

                    return FilterController.objects;
                }
            );
        }

        FilterController.modelToItemMethod = modelToItemMethod;
        function modelToItemMethod(modelValue) {
            return modelValue;
        }

        FilterController.select = select;
        function select(callback) {
            FilterController.selectedArray = DefaultVariableService.getArray(
                callback.selectedItemsArray
            );

            var selectedItem = FilterController.selectedArray;

            if (!FilterController.isArray && selectedItem.length !== 0) {
                selectedItem = selectedItem[0];
            }

            var arrayChanged = FilterController.isArray && FilterController.externalModel.length !== selectedItem.length;

            var idChanged = !FilterController.isArray && (
                FilterController.target.id !== selectedItem.id || FilterController.externalModel.id !== selectedItem.id
            );

            if (arrayChanged || idChanged) {
                FilterController.target = selectedItem;

                FilterController.onSelect(
                    FilterController.target
                );

                FilterController.setObjects();
            }
        }

        FilterController.setObjects = setObjects;
        function setObjects(objects) {
            FilterController.allObjects = DefaultVariableService.get(
                objects,
                FilterController.allObjects
            );

            FilterController.objects = FilterController.allObjects.filter(
                function(object) {
                    var target = DefaultVariableService.getArray(
                        FilterController.target
                    );

                    var targetLength = target.length;

                    for (var i = 0; i < targetLength; i++) {
                        var candidate = DefaultVariableService.getObject(
                            target[i]
                        );

                        if (object.id === candidate.id) {
                            return false;
                        }
                    }

                    return true;
                }
            );
        }

        FilterController.setTarget = setTarget;
        function setTarget(target) {
            FilterController.target = DefaultVariableService.get(
                target,
                FilterController.target
            );

            FilterController.externalModel = angular.copy(
                FilterController.target
            );
        }

        FilterController.reset = reset;
        function reset() {
            FilterController.description = DefaultVariableService.get(
                FilterController.description,
                'description'
            );

            FilterController.filterMessage = DefaultVariableService.get(
                FilterController.filterMessage,
                'FILTER...'
            );

            FilterController.title = DefaultVariableService.get(
                FilterController.title,
                'description'
            );

            FilterController.toggleMessage = DefaultVariableService.get(
                FilterController.toggleMessage,
                'SELECT...'
            );
        }
        
        FilterController.init = init;
        function init() {
            FilterController.reset();
        }
        
        FilterController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicFilter').directive('ionicFilter', ionicFilter);

    function ionicFilter() {
        return {
            bindToController: {
                description:     '@',
                filterMessage:   '@',
                title:           '@',
                toggleMessage:   '@'
            },
            controller:   'FilterController',
            controllerAs: 'ctrl',
            restrict:     'E',
            scope: {
                allowRemove: '=',
                onFilter:    '=',
                onSelect:    '=',
                target:      '='
            },
            template:'<div><label class="item item-input"><input data-ng-if="ctrl.allowRemove" ion-autocomplete type="text" class="ion-autocomplete" readonly open-class="ion-autocomplete-open" placeholder="{{ ctrl.toggleMessage }}" clear-on-select="true" select-items-label="AVAILABLE" selected-items-label="SELECTED" data-ng-model="ctrl.target" external-model="ctrl.externalModel" items-clicked-method="ctrl.select(callback)" items-removed-method="ctrl.select(callback)" item-view-value-key="{{ ctrl.title }}" items-method="ctrl.filter(query)" max-selected-items="ctrl.maxSelected" model-to-item-method="ctrl.modelToItemMethod(modelValue)"></label> <label data-ng-if="!ctrl.allowRemove" class="item item-input"><input ion-autocomplete type="text" class="ion-autocomplete" readonly open-class="ionicFilterHideRemove" placeholder="{{ ctrl.toggleMessage }}" clear-on-select="true" select-items-label="AVAILABLE" selected-items-label="SELECTED" data-ng-model="ctrl.target" external-model="ctrl.externalModel" items-clicked-method="ctrl.select(callback)" item-view-value-key="{{ ctrl.title }}" items-method="ctrl.filter(query)" max-selected-items="ctrl.maxSelected" model-to-item-method="ctrl.modelToItemMethod(modelValue)"></label></div>'
        };
    }
})();